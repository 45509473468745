import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/HigherEducationHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import ContactForm from "../contact/ContactForm";



function CaseStudy() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image vocational_bg" 
                  style={{ 
                        backgroundColor: '#E3FFFB', 
                        display: 'flex',
                        backgroundPosition: 'top', 
                        alignItems: 'center',
                        position: 'relative', 
                  }}
            >
                <ElementaryHeroTabs selectedTab={'case'}/>
                <div className="container">
                  <div className="row" style={{alignItems: 'center'}}>
                      
                      <div className="col-lg-6 about_hero_content">
                          <h2 style={{color: '#263238'}}>Case Study</h2>
                          <p style={{color: '#263238'}} >Revolutionizing education through innovative IoT solutions with ANTT Robotics</p>
                      </div>
                      
                      <div className="col-lg-6 about_hero_image" style={{alignSelf: 'flex-end'}}>
                          <img src="/assets/images/highered/case-study/hero.webp"/>
                      </div>
                      
                      
                  </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1 casestudy-body">
                
              <div className="service-details-inner">
                  <div className="inner">
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          

                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 130}}>
                              <div className="col-lg-6">
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/highered/case-study/1.webp" alt="About Images"/>
                                  </div>
                              </div>                                  
                              <div className="col-lg-6 enhance-hands-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
                                              {/* <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Boost Your Career Prospects With STEM-Powered Higher Education</h2> */}
                                              <p className="description">ANTT Robotics Ltd. and Philippines Integritech Builders Solutions Inc. & ACube Solutions  partnered to offer an innovative IoT education product to a well-known university in the Philippines.</p>
                                              <p className="description">The Microprocessor and Microcontroller Systems Lab training received overwhelming positive feedback, with more than 25 students attending the course. The curriculum focused on computer science, IoT, and robotics, which allowed the students to engage with the ecosystem effortlessly.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              
                          </div>
                          {/* End Single Area */}
                          {/* Start Single Area */}
                          <div className="row" style={{marginTop: 130, justifyContent: 'center'}}>
                            <div className="col-lg-8">
                              <h2 style={{textAlign: 'center', fontSize: 36}}>Empowering Students for Career Success with ANTT Robotics IoT Education Kit</h2>
                              <p style={{textAlign: 'center'}}>Developing 21st Century Technical and Future Skills for Real-World Relevance</p>
                            </div>
                          </div>
                          <div className="row" style={{marginTop: 70}}>
                              
                              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" style={{padding: 5}}>
                                  <div className="card service-card" style={{border: '1px solid rgba(0, 0, 0, 0.17)', backgroundColor: '#E3FFFB', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: '30px 10px', height: 430 }}>
                                      <img src="/assets/images/highered/case-study/2.webp" style={{marginBottom: 20}}/>
                                      <h3 style={{fontSize: 20}}>Teach technical skills</h3>
                                      <p style={{fontSize: 12, fontWeight: 600, color: '#252B42'}}>ANTT Robotics IoT Education Kit provides students with technical skills, such as coding and data analysis, that are necessary for success in 21st century careers.</p>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" style={{padding: 5}}>
                                  <div className="card service-card" style={{border: '1px solid rgba(0, 0, 0, 0.17)', backgroundColor: '#E3FFFB', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: '30px 10px', height: 430 }}>
                                      <img src="/assets/images/highered/case-study/3.webp" style={{marginBottom: 20}}/>
                                      <h3 style={{fontSize: 20}}>Boost future skills</h3>
                                      <p style={{fontSize: 12, fontWeight: 600, color: '#252B42'}}>Students improve future skills, including problem-solving and collaboration, as they learn to decompose complex problems, making them more career-ready.</p>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" style={{padding: 5}}>
                                  <div className="card service-card" style={{border: '1px solid rgba(0, 0, 0, 0.17)', backgroundColor: '#E3FFFB', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: '30px 10px', height: 430 }}>
                                      <img src="/assets/images/highered/case-study/4.webp" style={{marginBottom: 20}}/>
                                      <h3 style={{fontSize: 20}}>Real-world relevance</h3>
                                      <p style={{fontSize: 12, fontWeight: 600, color: '#252B42'}}> ANTT Robotics IoT Education Kit introduces students to the IoT and industry-standard technology, increasing their relevance in today's internet-connected work environment.</p>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" style={{padding: 5}}>
                                  <div className="card service-card" style={{border: '1px solid rgba(0, 0, 0, 0.17)', backgroundColor: '#E3FFFB', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: '30px 10px', height: 430 }}>
                                      <img src="/assets/images/highered/case-study/5.webp" style={{marginBottom: 20}}/>
                                      <h3 style={{fontSize: 20}}>Get career-ready</h3>
                                      <p style={{fontSize: 12, fontWeight: 600, color: '#252B42'}}>ANTT Robotics IoT Education Kit exposes students to possibilities beyond their current experience, providing them with the skills necessary to succeed in their future careers.</p>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 130}}>
                              <div className="col-lg-7">
                                  <div style={{position: 'relative'}}>
                                      <img style={{width: '96%'}} src="/assets/images/about/about-us-home.webp" alt="About Images"/>
                                  </div>
                              </div>                                  
                              <div className="col-lg-5 enhance-hands-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
    
                                              <p className="description">The success of the program is a testament to ANTT Robotics Ltd.'s teaching methods, which focused on engaging students in learning. In addition, ANTT Robotics Ltd. also provided add-on to Learn following</p>
                                              <ul style={{fontSize: 18, lineHeight: '32px'}}>
                                                <li>Controlling and Monitoring Home appliance using IoT Technology</li>
                                                <li>Learn Industrial Robotic Arm application using 3d printed mechanical arm tools</li>
                                                <li>Grow Home Mini Garden using IoT and Robotics Technology </li>
                                                <li>Learn about Maternety and Health Care using IoT and Robotic Sensors </li>
                                                <li>Design and Testing Autonomous Car </li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              
                          </div>
                          {/* End Single Area */}

                        

                          <div className="rn-contact-us ptb--30" id="contact" style={{marginTop: 120}}>
                            <div className="contact-form--1">
                                
                                <div className="row row--35" style={{alignItems: 'stretch'}}>
                                    <div className="col-12 order-2 order-lg-1">
                                        <div className="section-title text-left mb--50">
                                            <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                            <h2 style={{marginBottom: 15, fontSize: 40, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                            <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                        </div>
                                        <div className="form-wrapper">
                                            <ContactForm />
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                            <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                        </div>
                                    </div> */}
                                </div>
                                
                            </div>
                          </div>
                       
                      </div>
                      
                      {/* End Single Area */}
                      

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-460}  />

        </React.Fragment>
    )
}
export default CaseStudy;
