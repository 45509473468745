import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import {Link} from "react-router-dom";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";
import Contact from "../elements/contact/ContactTwo";
import ModalVideo from 'react-modal-video';

const list = [
    {
        image: 'image-1',
        category: 'Everbest Media',
        title: 'STEAM & Robotics Teaching Solutions'
    },
    {
        image: 'image-3',
        category: 'IoTKID LLC',
        title: 'loT & Robotics Training'
    },
    {
        image: 'image-2',
        category: 'Regnum IT Limited',
        title: 'loT Devices for Toll Management System'
    },
    {
        image: 'image-4',
        category: 'Showa Co., Ltd',
        title: 'loT & Al based Software-Hardware Development '
    },
    {
        image: 'image-5',
        category: 'AlMS LAB',
        title: 'COVID 19 Medical Care Robot'
    },
    {
        image: 'image-6',
        category: 'ICT Ministry of Bangladesh',
        title: 'Al &Speech Recognition'
    },
    {
        image: 'image-7',
        category: 'Champion',
        title: 'Bangladesh Frontier Startup, Seoul, SK'
    },
    {
        image: 'image-8',
        category: 'Low Cost Ventilators',
        title: 'UIU takes the lead in developing low-cost ventilators '
    },
    {
        image: 'image-9',
        category: '1st Runners Up ',
        title: 'Entrepreneurship World Cup 2019'
    }
]

let TeamContent = [
    {
        images: '1',
        title: 'Prepare For The Future Of Work',
        designation: 'Robotics Tool for Learning CS',
        // socialNetwork: [
        //     {
        //         icon: <FaFacebookF />,
        //         url: '#'
        //     },
        //     {
        //         icon: <FaLinkedinIn />,
        //         url: '#'
        //     },
        //     {
        //         icon: <FaTwitter />,
        //         url: '#'
        //     },
        // ]
    },
    {
        images: '2',
        title: 'Research & IP ',
        designation: 'Research & Intellectual Property Managed Team',
        // socialNetwork: [
        //     {
        //         icon: <FaFacebookF />,
        //         url: '#'
        //     },
        //     {
        //         icon: <FaLinkedinIn />,
        //         url: '#'
        //     },
        // ]
    },
    {
        images: '3',
        title: 'Certified & Experienced Target Oriented Team',
        designation: 'Arm addons for School of IoT',
        // socialNetwork: [
        //     {
        //         icon: <FaFacebookF />,
        //         url: '#'
        //     },
        //     {
        //         icon: <FaLinkedinIn />,
        //         url: '#'
        //     },
        //     {
        //         icon: <FaTwitter />,
        //         url: '#'
        //     },
        // ]
    },
    {
        images: '4',
        title: 'Allienged with UN SDG 4 & 8',
        designation: 'Car addons for School of IoT ',
        // socialNetwork: [
        //     {
        //         icon: <FaFacebookF />,
        //         url: '#'
        //     },
        //     {
        //         icon: <FaLinkedinIn />,
        //         url: '#'
        //     },
        //     {
        //         icon: <FaTwitter />,
        //         url: '#'
        //     },
        // ]
    },
];


class About extends Component{
    constructor(props) {
        super(props);
        
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        let title = 'About',
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />

                <HeaderFixed />
                {/* Start Breadcrump Area */}
                <div className="bg_image about_bg" 
                    style={{ 
                        backgroundColor: '#2A9DF4', 
                        display: 'flex',
                        backgroundPosition: 'top', 
                        alignItems: 'center',
                        position: 'relative', 
                    }}>
                    
                    <div className="container">
                        <div className="row" style={{alignItems: 'center'}}>
                            
                            <div className="col-lg-6 about_hero_content">
                                <h2>ABOUT US</h2>
                            </div>
                            
                            <div className="col-lg-6 about_hero_image" style={{alignSelf: 'flex-end'}}>
                                <img src="/assets/images/about/hero.webp"/>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div style={{marginBottom: -50}} className="about-area ptb--30" id="about">
                    <div className="about-wrapper">
                        <div className="container">  
                            <div className="row row--35 align-items-center">
                                
                                <div className="col-lg-12" style={{marginBottom: 50}}>
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                        <h2 style={{fontSize: 40}} className="title">About Us</h2>
                                        <p className="description">ANTT Robotics is dedicated to giving corporations, small and medium-sized businesses (SMBs), and academic institutions the tools they need to make the transition to Industry 4.0. We are sure that every business, no matter how big or small, should have access to cutting-edge technology that can help it grow, be more efficient, and be successful.</p>
                                    </div>
                                </div>
                                
                                <div className="col-lg-7">
                                    <div className="thumbnail" style={{position: 'relative'}}>
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='DQRPZca7uhM' onClose={() => this.setState({isOpen: false})} />
                                        <img className="video-popup" src="/assets/images/icons/play button icon.webp" onClick={this.openModal}/>
                                        <img className="w-100" src="/assets/images/about/about-us-smart-industry.png" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="about-us-list" style={{marginBottom: 50}}>
                                                <div className="section-title-colored2"><h3>Mission</h3></div>
                                                <p>Accelerating the fourth industrial revolution by developing the Business organization to integrate localized ecosystems using modern technology. </p>
                                            </div>
                                            <div className="about-us-list">
                                                <div className="section-title-colored2"><h3>Vision</h3></div>
                                                <p>Research of new technologies to developed products, solutions and training supports for educational and industrial organization. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                 {/* Start CounterUp Area */}
                 <div className="rn-counterup-area pt--70 p pb--50 bg_color--5">
                    <div className="container">
                        <div className="row" style={{justifyContent: 'center', marginBottom: 50}}>
                            <div className="col-lg-8">
                                <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                    <h2 className="title" style={{textAlign: 'center', fontSize: 40}}>Our Fun Facts</h2>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}


                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1">
                    <div className="container about-team-area" style={{transformOrigin: 'top'}}>
                        <div className="row team_header" style={{flexWrap: 'nowrap', marginBottom: 40}}>
                            <h2 style={{fontSize: 48}} className="title">Management <span style={{backgroundColor: 'rgba(63, 63, 217, 0.2)', padding: '0 10px'}}>Team</span> </h2>
                            <div style={{height: 80, width: 2.2, backgroundColor: '#3F3FD9'}}></div>
                            <p style={{fontSize: 15, flex: .8}}>We have a great team of passionate engineers, education professionals, innovative thinkers, business developers, and strategic thinkers with a wealth of experience from a variety of different backgrounds and locations</p>
                        </div>
                        <div className="row">
                            
                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div>
                                    <div className="thumbnail">
                                        <img style={{height: 480}} className="w-100" src="/assets/images/team/about-team-01.webp" alt="Blog Images"/>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 30}} className="content">
                                        <h4 style={{marginBottom: 6}} className="title">Uday Thajid</h4>
                                        <p style={{textAlign: 'center'}} className="designation">Co-founder & CEO</p>
                                    </div>
                                    
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div>
                                    <div className="thumbnail">
                                        <img style={{height: 480}} className="w-100" src="/assets/images/team/about-team-02.webp" alt="Blog Images"/>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 30}} className="content">
                                        <h4 style={{marginBottom: 6}} className="title">Nazib Ahmad </h4>
                                        <p style={{textAlign: 'center'}} className="designation">Co-founder & CTO </p>
                                    </div>
                                    
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div >
                                    <div className="thumbnail">
                                        <img style={{height: 480}} className="w-100" src="/assets/images/team/about-team-03.webp" alt="Blog Images"/>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 30}} className="content">
                                        <h4 style={{marginBottom: 6}} className="title">Afnan Islam</h4>
                                        <p style={{textAlign: 'center'}} className="designation">Director, R&D</p>
                                    </div>
                                    
                                </div>
                            </div>
                            {/* End Single Team  */}
                            {/* Start Single Team  */}
                            <div style={{marginTop: 40}} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div>
                                    <div className="thumbnail">
                                        <img style={{height: 480}} className="w-100" src="/assets/images/team/about-team-04.webp" alt="Blog Images"/>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 30}} className="content">
                                        <h4 style={{marginBottom: 6}} className="title">Tanzir Ahmmad</h4>
                                        <p style={{textAlign: 'center'}} className="designation">HOD, R&D </p>
                                    </div>
                                    
                                </div>
                            </div>
                            {/* End Single Team  */}
                            {/* Start Single Team  */}
                            <div style={{marginTop: 40}} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div>
                                    <div className="thumbnail">
                                        <img style={{height: 480}} className="w-100" src="/assets/images/team/about-team-05.webp" alt="Blog Images"/>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 30}} className="content">
                                        <h4 style={{marginBottom: 6}} className="title">Jayme Brown</h4>
                                        <p style={{textAlign: 'center'}} className="designation">Co-founder & Director of International Business Development</p>
                                    </div>
                                    
                                </div>
                            </div>
                            {/* End Single Team  */}
                            {/* Start Single Team  */}
                            <div style={{marginTop: 40}} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div>
                                    <div className="thumbnail">
                                        <img style={{height: 480}} className="w-100" src="/assets/images/team/about-team-06.webp" alt="Blog Images"/>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 30}} className="content">
                                        <h4 style={{marginBottom: 6}} className="title">Toriqul Islam</h4>
                                        <p style={{textAlign: 'center'}} className="designation">Founding Member, Sr. R&D Engineer</p>
                                    </div>
                                
                                </div>
                            </div>
                            {/* End Single Team  */}

                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Portfolio Area */}
                <div className="about-portfolio-area pb--30 bg_color--1" id="portfolio">
                    <div className="rn-slick-dot">
                        <div style={{transformOrigin: 'top'}} className="container">
                            <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-lg-8">
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                        <h2 className="title" style={{textAlign: 'center', fontSize: 40}}>Our Portfolio</h2>
                                        <p style={{textAlign: 'center'}}>With support from the local governments in Bangladesh and South Korea, we have collaborated in many different sectors for the promotion of advanced business automation, robotics products, fourth industrial products, and software solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {list.map((value , index) => (
                                                <div className="portfolio" key={index} style={{display: 'flex', flexDirection: 'column'}}>        
                                                    <div className="thumbnail-inner">
                                                        <div className="thumbnail-overlay">
                                                            <div className="portfolio-button">
                                                                <a className="rn-btn" href={value.details}>Case Study</a>
                                                            </div>
                                                        </div>
                                                        <div className={`thumbnail ${value.image}`}></div>
                                                        {/* <div className={`bg-blr-image ${value.image}`}></div> */}
                                                    </div>
                                                    <div className="content" style={{marginTop: 26}}>
                                                        <div className="inner">
                                                            <p style={{textAlign: 'center', marginBottom: 6}}>{value.category}</p>
                                                            <h4 style={{textAlign: 'center'}}><a href={value.details}>{value.title}</a></h4>
                                                            
                                                        </div>
                                                    </div>
                                                    {/* <Link className="link-overlay" to={value.details}></Link> */}
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Finding Us Area  */}
                {/* <div className="rn-finding-us-area rn-finding-us">
                    <div style={{transform: 'scale(0.8)'}} className="inner">
                        <div className="content-wrapper">
                            <div className="content">
                                <p>ANTT Robotics is proud of our highly qualified, award winning team of engineers, business professionals, and educators.</p>
                                <a className="rn-btn btn-white" href="/contact">Contact us</a>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/finding-us-02.jpeg" alt="Finding Images"/>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div style={{transformOrigin: 'top'}} className="container">
                    <div style={{ backgroundColor: '#3F3FD9'}} className="row full_team">
                        <div className="col-lg-7 team_1_image"><img style={{width: '100%', borderRadius: 8}} src="/assets/images/about/finding-us-02.jpeg" alt="Finding Images"/></div>
                        <div style={{
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                        }} className="col-lg-5 team_1_content">
                            <p style={{color: 'white', marginBottom: 40}}><span style={{fontWeight: 700}}>ANTT Robotics</span> is proud of our highly qualified, award winning team of engineers, business professionals, and educators.</p>
                            <a style={{backgroundColor: 'white', color: '#645B5B'}} className="rn-btn btn-white" href="/contact">Contact us</a>
                        </div>
                    </div>
                </div>
                
                {/* End Finding Us Area  */}

                {/* Start Team Area  */}
                <div className="about-team-area bg_color--1" id="team">
                    <div style={{transformOrigin: 'top'}} className="container">
                        <div className="row" style={{justifyContent: 'center'}}>
                            <div className="col-lg-8">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 style={{fontSize: 40, textAlign: 'center'}} className="title">Core Competencies & Strategic Capabilities</h2>
                                    {/* <p>ANTT Robotics Smart Ecosystem Services Will Help You Get Started With Your Robotics Business.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {TeamContent.map((value , i ) => (
                                <div style={{padding: '0 5px'}} className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="team" >
                                        <div style={{
                                            height: 360, 
                                            display: 'flex', 
                                            alignItems: 'baseline', 
                                            justifyContent: 'center', 
                                            paddingTop: 40,
                                            backgroundColor: '#5255FF'
                                        }} className="thumbnail">
                                            <img style={{height: 190, width: 'auto'}}  src={`/assets/images/team/Core Competencies-${value.images}.webp`} alt="Blog Images"/>
                                        </div>
                                        <div className="content">
                                            <h4 style={{fontSize: 18}} className="title">{value.title}</h4>
                                            {/* <p  className="designation">{value.designation}</p> */}
                                        </div>
                                        {/* <ul className="social-icon" >
                                            {value.socialNetwork.map((social, index) =>
                                                <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                            )}
                                        </ul> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Brand Area */}
                <div className="about-brand-area" style={{paddingTop: 0}}>
                    <div style={{ transformOrigin: 'top'}} className="container">
                        <div className="row">
                            <div style={{padding: 0}} className="col-lg-12">
                                <ul className="brand-style-2">
                                    <li>
                                        <img src="/assets/images/brand/brand-01.png" alt="Logo Images"/>
                                    </li>
                                    <li>
                                        <img src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                                    </li>
                                    <li>
                                        <img src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                                    </li>
                                    <li>
                                        <img src="/assets/images/brand/brand-04.png" alt="Logo Images"/>
                                    </li>
                                    
                                    <li>
                                        <img src="/assets/images/brand/brand-06.png" alt="Logo Images"/>
                                    </li>
                                    <li>
                                        <img src="/assets/images/brand/brand-07.png" alt="Logo Images"/>
                                    </li>
                                    
                                    <li>
                                        <img src="/assets/images/brand/brand-09.png" alt="Logo Images"/>
                                    </li>
                                    
                                    <li>
                                        <img src="/assets/images/brand/brand-11.png" alt="Logo Images"/>
                                    </li>
                                    
                                    
                                    <li>
                                        <img src="/assets/images/brand/brand-14.png" alt="Logo Images"/>
                                    </li>
                                    
                                    <li>
                                        <img src="/assets/images/brand/brand-16.png" alt="Logo Images"/>
                                    </li>
                                    <li>
                                        <img src="/assets/images/brand/brand-17.png" alt="Logo Images"/>
                                    </li>
                                    <li>
                                        <img src="/assets/images/brand/brand-18.png" alt="Logo Images"/>
                                    </li>
                                    
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Finding Us Area  */}
                <div style={{transformOrigin: 'top'}} className="container">
                    <div style={{ backgroundColor: '#3F3FD9'}} className="row full_team">
                        <div className="col-lg-7"><img style={{width: '100%', borderRadius: 8}} src="/assets/images/about/finding-us-03.jpeg" alt="Finding Images"/></div>
                        <div style={{
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                        }} className="col-lg-5 team_2_content">
                            <h4 style={{color: 'white', fontSize: 30}}>Promoting Girls in STEAM</h4>
                            <p style={{color: 'white', marginBottom: 40}}>ANTT Robotics sought to encourage girls to participate in STEAM and gain future skills.</p>
                            <a style={{backgroundColor: 'white', color: '#645B5B'}} className="rn-btn btn-white" href="/contact">Contact us</a>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

                {/* Start Contact Us */}
                <div className="about-contact-us" id="contact">
                    <Contact />
                </div>
                {/* End Contact Us */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer topMargin={100} />

            </React.Fragment>
        )
    }
}
export default About