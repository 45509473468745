import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";
import ContactForm from "./contact/ContactForm";

class ServiceRobot extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <HeaderFixed />

                {/* Start Breadcrump Area */}
                <div className="bg_image about_bg"
                    style={{
                        backgroundColor: '#5255FF',
                        display: 'flex',
                        backgroundPosition: 'top',
                        alignItems: 'center',
                        position: 'relative',
                    }}>

                    <div style={{ transform: 'scale(0.8)', transformOrigin: 'center center' }} className="container">
                        <div className="row" style={{ alignItems: 'center' }}>

                            <div className="col-lg-8 about_hero_content">
                                <h2>SERVICE ROBOT</h2>
                                <p>Full Stack Robotic Solution for Large Organizations, Government Agencies/Offices, Museums, Airports, and Industry, among others</p>
                            </div>

                            <div className="col-lg-4 about_hero_image" style={{ alignSelf: 'flex-end' }}>
                                <img src="/assets/images/service/service-robot/hero.webp" />
                            </div>


                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--80 bg_color--1 service-robot">
                    <div style={{ transform: 'scale(0.8)', transformOrigin: 'top center' }} className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>
                                            <p>Technology is a part of all of our lives, and as time goes on, we depend more and more on technology. Service robots are able to perform the same tasks that humans do. They have sensors that can detect if a surface is dirty, and they are able to clean it. Service robots also have sensors that can detect when food has been spilled or burnt, and they can remove spilled food.</p>
                                            <p style={{ marginBottom: 30 }}>Maysha is the first Bangladeshi industrial-grade service robot with the ability to interact and perform in both Bengali and English language while completing tasks.</p>
                                            <h2 style={{ alignSelf: 'flex-start', fontSize: 40 }} className="title">Advantages and Benefits</h2>

                                            <ul style={{ color: '#717173', fontWeight: 300, fontSize: 18, alignSelf: 'flex-start' }}>
                                                <li>Can be used as a front desk or driving receptionist with a touch screen.</li>
                                                <li>Can be used for daily office and household work.</li>
                                            </ul>

                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row align-items-center" style={{ marginTop: 120 }}>
                                            <div className="col-lg-6 front-desk-image" style={{ paddingRight: 0 }}>
                                                <div style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: '#F0F9FF',
                                                    borderRadius: 15,
                                                }}>
                                                    <img style={{ width: '80%' }} src="/assets/images/service/service-robot/1.webp" alt="About Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6" style={{ display: 'flex', }}>
                                                <div className="about-inner inner" style={{ display: 'flex', padding: '0 50px' }}>
                                                    <div className="row">
                                                        <div className="section-title service-style--3 text-left mb_sm--0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0 }}>
                                                            <h2 style={{ fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%' }} className="title">Front desk Receptionist Robot</h2>
                                                            <p className="description">This robot comes with AC power that stays at one point and has a 21.5" interactive touch screen for getting information and requesting an appointment schedule</p>
                                                            <p style={{ marginBottom: 50 }} className="description">This robot comes with two (02) DC-powered semi-autonomous driving wheels and has a 21.5" interactive touch screen for getting information and requesting an appointment schedule.</p>
                                                            <h2 style={{ fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%' }} className="title">Applicable Industries</h2>
                                                            <p className="description">This robot comes with two (02) DC-powered semi-autonomous driving wheels and has a 21.5" interactive touch screen for getting information and requesting an appointment schedule.</p>
                                                            <p className="description">Hotels, Garment Stores, Building Material Stores, Manufacturing Plants, Machinery Repair Shops, Food & Beverage Factory, Farms, Restaurants, Home Use, Retail, Food Store, Printing Shops, Construction Works, Energy & Mining, Food & Beverage Shops, Advertising Company</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row align-items-center" style={{ marginRight: 0, marginTop: 120 }}>

                                            <div className="col-lg-6 public-service-content" style={{ padding: '0 50px', display: 'flex' }}>
                                                <div className="about-inner inner" style={{ display: 'flex' }}>
                                                    <div className="row">
                                                        <div className="section-title service-style--3 text-left mb_sm--0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0 }}>
                                                            <h2 style={{ fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%' }} className="title">Function: Public Service Business Solution</h2>
                                                            <p className="description">Service robots are developed and produced in factories in Bangladesh. ANTT Robotics has strong brand recognition, and after signing a contract, clients can take advantage of semi-customizable design, specifications, and even pricing strategies. After-sales services are available upon special request, third-party after-sales service solutions are possible for international clients. Robot parts warranty is not possible, though lifetime service support is offered. Service robots Ambient temperatures are about -10°C–40°C for glass fiber materials. Voice recognition features for Bengali and English are available on service robots. Driving cameras are available for safety and manual driving. Robots can communicate with any smart device via a wifi, 2.4 GHz, or 5 GHz network.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6" style={{ paddingLeft: 0 }}>
                                                <ul style={{ color: '#717173', fontWeight: 300, fontSize: 18, alignSelf: 'flex-start', margin: '20px 0' }}>
                                                    <h6 style={{ fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%' }} className="title">Specifications</h6>
                                                    <li>Motherboard: in-house custom-made</li>
                                                    <li>Processor: Quad-core Cortex-A72 (ARM v8) 64-bit SoC @ 1.5 GHz</li>
                                                    <li>RAM: 4GB SDRAM or 8GB SDRAM</li>
                                                    <li>Sensors: sonar sensors, infrared sensors, compass</li>
                                                    <li>Battery: LiPo</li>
                                                    <li>Charging Station: Yes</li>
                                                    <li>Audio: Two-Channel Stereo Speaker</li>
                                                    <li>Screen: 15" x 21.5" touch screen</li>
                                                </ul>
                                                {/*  <div className="col-lg-6 public-service-image" style={{paddingLeft: 0}}> <img src="/assets/images/service/service-robot/2.webp" alt="About Images"/>                                  */}
                                            </div>
                                        </div>
                                        {/* End Single Area */}




                                        <div className="rn-contact-us ptb--30" id="contact">
                                            <div className="contact-form--1">

                                                <div className="row row--35" style={{ alignItems: 'stretch', marginTop: 150 }}>
                                                    <div className="col-12 order-2 order-lg-1">
                                                        <div className="section-title text-left mb--50">
                                                            <p style={{ color: '#2c7fc2', marginBottom: 2, fontSize: 30 }}>Interested?</p>
                                                            <h2 style={{ marginBottom: 15, fontSize: 40, fontWeight: 400 }} className="title">Book your <span style={{ fontWeight: 600 }}>Free</span> Session</h2>
                                                            <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                                        </div>
                                                        <div className="form-wrapper">
                                                            <ContactForm />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6 order-1 order-lg-2">
                                                    <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                                        <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                                    </div>
                                                </div> */}
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
                {/* End Back To Top */}

                <Footer topMargin={0} />

            </React.Fragment>
        )
    }
}
export default ServiceRobot;