import React, { useState } from "react";
import PageHelmet from "../component/common/Helmet";

import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";

import 'react-accessible-accordion/dist/fancy-example.css';
import ContactForm from "./contact/ContactForm";



function CodingCertificate() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image about_bg" 
                 style={{ 
                    backgroundColor: '#1A89CA', 
                    display: 'flex',
                    backgroundPosition: 'top', 
                    alignItems: 'center',
                    position: 'relative',  
                }}>
                
                <div style={{transform: 'scale(0.8)'}} className="container">
                    <div className="row" style={{alignItems: 'center'}}>
                        
                        <div className="col-lg-6 about_hero_content">
                            <h2>CODING CERTIFICATE</h2>
                        </div>

                        <div className="col-lg-6 about_hero_image" style={{alignSelf: 'flex-end'}}>
                            <img src="/assets/images/coding_certificate/Coding Certificate-hero.webp"/>
                        </div>
                        
                    
                        
                        
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--120 bg_color--1">
                <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    
                                    <div className="why-should section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <h2 style={{alignSelf: 'flex-start', marginBottom: 80}} className="title">Why Should Educators be familiar with STEAM and Coding education?</h2>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6" style={{padding: 10}}>
                                                <div className="card service-card" style={{border: 'none', backgroundColor: '#F0FDFA', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: 50, height: 430 }}>
                                                    <img src="/assets/images/coding_certificate/1.webp" style={{marginBottom: 30}}/>
                                                    <p style={{fontSize: 18, fontWeight: 600, color: '#252B42'}}>To help students develop critical thinking and problem-solving skills, which are essential for success in any field</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6" style={{padding: 10}}>
                                                <div className="card service-card" style={{border: 'none', backgroundColor: '#F0FDFA', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: 50, height: 430 }}>
                                                    <img src="/assets/images/coding_certificate/2.webp" style={{marginBottom: 30}}/>
                                                    <p style={{fontSize: 18, fontWeight: 600, color: '#252B42'}}>To expose students to various career possibilities in the STEAM fields, such as healthcare, manufacturing, computer programming, and engineering.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6" style={{padding: 10}}>
                                                <div className="card service-card" style={{border: 'none', backgroundColor: '#F0FDFA', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: 50, height: 430 }}>
                                                    <img src="/assets/images/coding_certificate/3.webp" style={{marginBottom: 30}}/>
                                                    <p style={{fontSize: 18, fontWeight: 600, color: '#252B42'}}>To prepare students for the digital world and the integration of technology in their future careers.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6" style={{padding: 10}}>
                                                <div className="card service-card" style={{border: 'none', backgroundColor: '#F0FDFA', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: 50, height: 430 }}>
                                                    <img src="/assets/images/coding_certificate/4.webp" style={{marginBottom: 30}}/>
                                                    <p style={{fontSize: 18, fontWeight: 600, color: '#252B42'}}>To give students the ability to understand and navigate the technology they interact with in their daily lives.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6" style={{padding: 10}}>
                                                <div className="card service-card" style={{border: 'none', backgroundColor: '#F0FDFA', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: 50, height: 430 }}>
                                                    <img src="/assets/images/coding_certificate/5.webp" style={{marginBottom: 30}}/>
                                                    <p style={{fontSize: 18, fontWeight: 600, color: '#252B42'}}>To foster creativity and innovation in students by providing hands-on learning experiences through coding and robotics.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row" style={{marginTop: 150}}>
                                        <div className="col-lg-4 col-md-6" style={{paddingRight: 10, paddingLeft: 10, paddingBottom: 20}}>
                                            <div className="coding-cert-numbers service-card" style={{backgroundColor: '#FFF9F2'}}>
                                                <div style={{backgroundColor: '#F78F1E'}}>
                                                    <h3>$7.5</h3>
                                                    <h3 style={{fontWeight: 400}}>Trillion</h3>
                                                </div>
                                                <p>The World Economic Forum estimated that the lack of skills could result in a loss of up to $7.5 trillion in economic growth by 2030.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6" style={{paddingRight: 10, paddingLeft: 10, paddingBottom: 20}}>
                                            <div className="coding-cert-numbers service-card" style={{backgroundColor: '#F5FBFF'}}>
                                                <div style={{
                                                    backgroundColor: '#2C7FC2',

                                                }}>
                                                    <h3 style={{fontWeight: 500}}>2.5</h3>
                                                    <h3 style={{fontWeight: 400}}>Million</h3>
                                                </div>
                                                <p>There is a significant shortage of qualified STEM workers worldwide, with an estimated 2.4 million unfilled STEM jobs in 2020. This gap is expected to grow in the coming years as the demand for STEM skills increases.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6" style={{paddingRight: 10, paddingLeft: 10, paddingBottom: 20}}>
                                            <div className="coding-cert-numbers service-card" style={{backgroundColor: '#FFF5F7'}}>
                                                <div style={{
                                                    backgroundColor: '#ED1B34',
                                                }}>
                                                    <h3>38%</h3>
                                                    
                                                </div>
                                                <p>Study by ManpowerGroup found that 38% of employers globally are struggling to fill jobs due to a lack of available talent with the right skills.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    <div className="row" style={{justifyContent: 'center', alignItems: 'center', marginTop: 150}}>
                                        <div className="col-lg-8 col-12" style={{marginBottom: 30}}>
                                            <div className="coding-steam-cert text-left mb--30">
                                                <h2 style={{textAlign: 'center', fontWeight: 600}}>Coding & STEAM Certificate for Educators</h2>
                                                <p style={{textAlign: 'center', fontSize: 16, fontWeight: 600}}>ANTT offers workshops, webinars and other training opportunities to help educators stay up-to-date on the latest teaching strategies and technology trends. These opportunities enable educators to enhance their skills and knowledge, providing better quality of education to students.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <img src="/assets/images/coding_certificate/Coding _ STEAM.webp" style={{width: '100%', backgroundColor: '#F0FDFA'}}/>
                                        </div>
                                    </div>

                                    <div className="row coding-cert-card-holder">
                                        <div className="coding-cert-cards">
                                            <div style={{
                                                position: 'absolute', 
                                                left: 0, 
                                                bottom: 0,
                                                top: 0,
                                                color: 'white',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                backgroundColor: '#1A89CA',
                                                writingMode: 'vertical-lr',
                                                borderTopLeftRadius: 20,
                                                borderBottomLeftRadius: 20,
                                                padding: '0 16px'
                                            }}><p style={{transform: 'rotate(-180deg)', fontSize: 18, fontWeight: 700, textAlign: 'center'}}>Ideal for</p></div>
                                            <p>Corporate, Business organization, Training Institutes, Preschool Educator, Elementary School Educator, Middle School Educator, High School Educator, Institutes need STEAM teacher training</p>
                                        </div>
                                        <div style={{                               
                                            // marginLeft: -26,                                
                                            // padding: '40px 60px 40px 100px'
                                        }} className="coding-cert-cards coding-card">
                                            <div style={{
                                                position: 'absolute', 
                                                left: 0, 
                                                bottom: 0,
                                                top: 0,
                                                color: 'white',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                backgroundColor: '#1A89CA',
                                                writingMode: 'vertical-lr',
                                                borderTopLeftRadius: 20,
                                                borderBottomLeftRadius: 20,
                                                padding: '0 16px'
                                            }}><p style={{transform: 'rotate(-180deg)', fontSize: 18, fontWeight: 700, textAlign: 'center'}}>Credit Hour</p></div>
                                            <p><span style={{fontWeight: 600}}>3</span> Credit Hour, <span style={{fontWeight: 600}}>12</span> Credit Hour, <span style={{fontWeight: 600}}>36</span> Credit Hour</p>
                                        </div>
                                        <div style={{
                                            // marginLeft: -26, 
                                            // padding: '40px 39px 40px 100px'
                                        }} className="coding-cert-cards">
                                            <div style={{
                                                position: 'absolute', 
                                                left: 0, 
                                                bottom: 0,
                                                top: 0,
                                                color: 'white',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                backgroundColor: '#1A89CA',
                                                writingMode: 'vertical-lr',
                                                borderTopLeftRadius: 20,
                                                borderBottomLeftRadius: 20,
                                                padding: '0 16px'
                                            }}><p style={{transform: 'rotate(-180deg)', fontSize: 18, fontWeight: 700, textAlign: 'center'}}>Completion benefit</p></div>
                                            <p>Coding / STEAM Certificate issued</p>
                                        </div>
                                    </div>

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginTop: 130, marginLeft: -4, marginRight: 0}}>                                  
                                        <div className="col-lg-5 enhance-teach-content">
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0">
                                                        <h2 style={{fontSize: 40, marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Enhance your teaching capabilities</h2>
                                                        <p style={{marginBottom: 40}} className="description">Preschool educators can enhance their skills and knowledge by obtaining a coding and STEAM certificate. This certificate program will provide teachers with the latest tools and techniques to integrate coding and STEAM education in their curriculum, which in turn will help them to support the development and learning of their students more effectively.</p>
                                                        <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" style={{border: '2px solid #2c7fc2', backgroundColor: '#2c7fc2', color: 'white'}} href="#">Sample Curriculum</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 enhance-teach-image">
                                            <div style={{position: 'relative'}}>
                                                <img className="w-100" src="/assets/images/coding_certificate/Enhance your teaching capabilities-Img.webp" alt="About Images"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginTop: 30, marginLeft: -4, marginRight: 0}}>
                                        <div className="col-lg-7">
                                            <div style={{position: 'relative'}}>
                                                <img className="w-100" src="/assets/images/coding_certificate/Enhancing hands-on learning-img.webp" alt="About Images"/>
                                            </div>
                                        </div>                                  
                                        <div className="col-lg-5 enhance-hands-content">
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="service-style--3 text-left mb_sm--0">
                                                        <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Enhancing hands-on learning</h2>
                                                        <p style={{marginBottom: 40}} className="description">Our coding and STEAM curriculum for elementary school teachers offers opportunities for interactive and practical education, which can increase student engagement and understanding of key concepts. With our resources and programming classes, educators can elevate their teaching and provide hands-on learning experiences for their students.</p>
                                                        <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" style={{border: '2px solid #2c7fc2', backgroundColor: '#2c7fc2', color: 'white'}} href="#">Sample Curriculum</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginTop: 30, marginLeft: -4, marginRight: 0}}>                                  
                                        <div className="col-lg-5 stay-ahead-content">
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="service-style--3 text-left mb_sm--0">
                                                        <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Stay ahead of the technology curve and give your students a competitive edge</h2>
                                                        <p style={{marginBottom: 40}} className="description">Enhance your teaching capabilities and equip your students with the skills they need for success in the digital age by taking advantage of our specialized coding and STEAM education training, tailored specifically for middle and high school educators. Stay ahead of the technology curve and give your students a competitive edge in the digital world by mastering the latest teaching methods and techniques in coding and STEAM education.</p>
                                                        <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" style={{border: '2px solid #2c7fc2', backgroundColor: '#2c7fc2', color: 'white'}} href="#">Sample Curriculum</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 stay-ahead-image">
                                            <div style={{position: 'relative'}}>
                                                <img className="w-100" src="/assets/images/coding_certificate/Stay ahead of the technology curve-img.webp" alt="About Images"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginTop: 30, marginLeft: -4, marginRight: 0}}>
                                        <div className="col-lg-7">
                                            <div style={{position: 'relative'}}>
                                                <img className="w-100" src="/assets/images/coding_certificate/Stay ahead of the technology curve-img (1).webp" alt="About Images"/>
                                            </div>
                                        </div>                                  
                                        <div className="col-lg-5 elevate-team-content">
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="service-style--3 text-left mb_sm--0">
                                                        <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Elevate your team's (employees) problem-solving and critical thinking skills</h2>
                                                        <p style={{marginBottom: 40}} className="description">Through coding and STEAM training. Enhance innovation, creativity, communication, teamwork and automation of daily tasks with no-coding solutions. Keep your business competitive with training opportunities provided by our organization.</p>
                                                        <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" style={{border: '2px solid #2c7fc2', backgroundColor: '#2c7fc2', color: 'white'}} href="#">Sample Curriculum</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Contact Us */}
                                    <div className="rn-contact-us ptb--30" id="contact">
                                        <div className="contact-form--1">
                                            
                                            <div className="row row--35 coding-cert-contact" style={{alignItems: 'stretch', marginTop: 120}}>
                                                <div className="col-12 order-2 order-lg-1">
                                                    <div className="text-left mb--50">
                                                        <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                                        <h2 style={{marginBottom: 15, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                                        <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                                    </div>
                                                    <div className="form-wrapper">
                                                        <ContactForm />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6 order-1 order-lg-2">
                                                    <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                                        <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                                    </div>
                                                </div> */}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {/* End Contact Us */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-1300}  />

        </React.Fragment>
    )
}
export default CodingCertificate;