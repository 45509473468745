import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/ElementaryHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import ContactForm from "../contact/ContactForm";


function ProductsServices() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image education-hero" style={{ backgroundImage: 'url(/assets/images/bg/Product-and-Services-hero-img.webp)' }}>
                <ElementaryHeroTabs selectedTab={'product'}/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center pt--100">
                                <h2>PRODUCT & SERVICES</h2>
                                <p style={{fontSize: 20}}>Unlocking the Digital Future for Preschool Educators</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1">
                
              <div className="service-details-inner">
                  <div className="inner">
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                        <div className="col-lg-12">
                          <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                              <h2 style={{marginBottom: 80, fontSize: 36, textAlign: "center"}} className="title">Unlock Interactive and Engaging Learning with ANTT's Curriculum Resources for Elementary Teachers</h2>
                              <img style={{marginBottom: 70}} src="/assets/images/education/1 (1).webp"/>
                              <p className="description">As an elementary school teacher, you are always on the lookout for ways to make your lessons more interactive and engaging for your students. With ANTT, you can do just that. Our curriculum and lesson planning resources are designed with the latest teaching methods in mind and are standards-aligned to ensure that your students are getting the best education possible. These resources include pre-made lesson plans, activities, and assessments that are easy to implement and are designed to be interactive and engaging for students.</p>
                              
                          </div>
                        </div>
                      </div>
                      
                      {/* End Single Area */}

                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                        <div className="col-lg-12">
                          <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0}}>                                  
                            <div className="col-lg-6" >
                              <div style={{
                                height: 460,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#F0F9FF'
                              }}>
                                <img style={{width: '80%'}} src="/assets/images/education/2 (1).webp" alt="About Images"/>
                              </div>
                              
                            </div>
                            <div className="col-lg-6" style={{paddingLeft: 50}}>
                                <div className="about-inner inner">
                                    <div className="row">
                                        <div className="section-title service-style--3 text-left mb_sm--0">
                                            <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Boost Your Teaching Skills with ANTT's Professional Development Opportunities</h2>
                                            <p className="description">Unlock the potential of your students with ANTT Robotics' STEAM education products. Our no-coding platform and gamified sessions make it easy for elementary school teachers to guide their students in solving real-world problems through hands-on learning and computer programming. With our tools and resources, you can take your teaching beyond the classroom and inspire your students to think critically and creatively.</p>
                                            {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: 40}}>                                  
                            
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="row">
                                        <div className="section-title service-style--3 text-left mb_sm--0">
                                            <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Real-World Problem-Solving with ANTT Robotics</h2>
                                            <p className="description">A STEAM Education Experience for Elementary School Teacher. Unlock the potential of your students with ANTT Robotics' STEAM education products. Our no-coding platform and gamified sessions make it easy for elementary school teachers to guide their students in solving real-world problems through hands-on learning and computer programming. With our tools and resources, you can take your teaching beyond the classroom and inspire your students to think critically and creatively.</p>
                                            {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                              <div style={{
                                height: 460,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#F0F9FF'
                              }}>
                                <img style={{width: '80%'}} src="/assets/images/education/3 (1).webp" alt="About Images"/>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Single Area */}

                      {/* Start Single Area */}
                      <div style={{backgroundColor: '#F2FCFF', marginTop: -100, paddingTop: 130}}>
                        <div style={{transform: 'scale(0.8)', transformOrigin: 'top center', }} className="container">
                          <div className="col-lg-12">
                            <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <h2 style={{textAlign: 'center', marginBottom: 80, fontSize: 36}} className="title">Unlock the Potential of Coding and Robotics with ANTT Robotics - Plug-and-Play Solutions for Elementary School Teachers.</h2>
                                <img style={{marginBottom: 70}} src="/assets/images/education/4 (1).webp"/>
                                <p className="description">Our easy-to-use hardware tools and no-coding platform make it simple for educators to integrate fourth industrial revolution skills into their curriculum. From computer science to electronics, coding to robotics, and regular teaching software to STEAM projects, we have everything elementary school teachers need to take their students' learning to the next level.</p>
                            </div>

                            <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: 150}}>                                  
                              <div className="col-lg-6" >
                                <img  src="/assets/images/education/5 (1).webp" alt="About Images"/>
                                
                              </div>
                              <div className="col-lg-6" style={{paddingLeft: 60}}>
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Unlock the Power of Programming and Robotics with ANTT</h2>
                                              <p className="description">Advanced Coding Courses for C, Python and MicroPython. Elevate your skills and knowledge to embark on the journey of the fourth industrial revolution. Our expert-led tutorials and courses are tailored for the educators to make them career ready for the world of IoT and programming. Join us and develop dependable expertise in coding and IoT with ANTT Robotics.</p>
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                            <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: 150}}>                                  
                              
                              <div className="col-lg-6">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Unlock the Potential of Coding and Robotics with ANTT Robotics - Plug-and-Play Solutions for Elementary School Teachers.</h2>
                                              <p className="description">Explore the exciting world of robotics and technology with ANTT Robotics' all-in-one STEM DIY Box for elementary school teachers. Our specially curated box is filled with simple sensors and components that make it easy for teachers to control robots wirelessly and complete fun projects. With our step-by-step guides and detailed instructions, educators can easily learn to program and control the robots, while engaging their students in hands-on learning experiences. Unlock the potential of your students and take the first step in the fourth industrial revolution with ANTT Robotics' STEM DIY Box.</p>
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6" >
                                <img  src="/assets/images/education/6.webp" alt="About Images"/>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      {/* End Single Area */}

                      {/* Start Single Area */}
                      {/* <div className="row align-items-center" style={{marginTop: 150, marginLeft: -4, marginRight: 0}}>                                  
                          
                          <div className="col-lg-7">
                              <div className="about-inner inner">
                                  <div className="row">
                                      <div className="section-title service-style--3 text-left mb_sm--0">
                                          <h2 style={{fontSize: 45, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Digital literacy training for educators is crucial in today's digital age.</h2>
                                          <p style={{marginBottom: 40}} className="description">As technology plays an increasingly important role in our daily lives, educators must have the skills and knowledge to effectively integrate technology and digital tools in the classroom. Our digital literacy training program is designed to help educators develop the skills they need to use technology and digital tools to access, analyze, and create information.</p>
                                          <p style={{marginBottom: 40}} className="description">Our training program includes a wide range of resources and tools that educators can use to enhance their instruction and engage students in learning.We provide all classroom solutions for STEAM laboratory, interior, teaching resources, handbook, quizzes for students and handson training session. Furthermore, we provide training on analytics and data tracking tools that allow educators to monitor student progress and make data-driven decisions about instruction.</p>
                                          <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-5">
                              <div className="thumbnail" style={{position: 'relative'}}>
                                  <img className="w-100" src="/assets/images/education/Digital literacy training_.webp" alt="About Images"/>
                              </div>
                          </div>
                      </div> */}
                      {/* End Single Area */}

                      {/* Start Contact Us */}
                      <div style={{marginTop: -280, paddingTop: 130, backgroundColor: '#fff'}}>
                        <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          <div className="rn-contact-us ptb--30" id="contact">
                            <div className="contact-form--1">
                                
                                <div className="row row--35" style={{alignItems: 'stretch'}}>
                                    <div className="col-12 order-2 order-lg-1">
                                        <div className="section-title text-left mb--50">
                                            <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                            <h2 style={{marginBottom: 15, fontSize: 40, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                            <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                        </div>
                                        <div className="form-wrapper">
                                            <ContactForm />
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                            <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                        </div>
                                    </div> */}
                                </div>
                                
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    {/* End Contact Us */}
                      

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={0}  />

        </React.Fragment>
    )
}
export default ProductsServices;
