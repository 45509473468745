import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs selectedIndex={1}>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>A great platform to play and learn programming skills! ANTT Robotics is perfect platform to start an edutech business with their STEAM tools, books, training and support.</p>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Technology has always excited me. After using and evaluating countless edu-tech and coding solutions, it's clear that ANTT Robotics' approach to education is what we need in a rapidly changing environment. Their dream team has the right blend of intelligence, experience, passion, and work ethic to be a success.</p>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The project has been a great opportunity for the Bangladeshi teams to raise their startup competitiveness to the next level. I am confident that all teams will continue the journey of great challenges to grow as a next generation leader in Bangladeshi’s ICT field.</p>
                                    </div>
                                </div>
                            </TabPanel>
                         
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.png" alt="Testimonial Images"/>
                                        </div>
                                        <h6>{'Patricia Ishemo'.toUpperCase()}</h6>
                                        <p>CEO, IOTKID LLC</p>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.png" alt="Testimonial Images"/>
                                        </div>
                                        <h6>{'Mr Andrei Calinescu'.toUpperCase()}</h6>
                                        <p></p>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-3.png" alt="Testimonial Images"/>
                                        </div>
                                        <h6>{'Zunaid Ahmed Palak'.toUpperCase()}</h6>
                                        <p>MP, State Minister, Bangladesh's ICT Division</p>
                                    </div>
                                </Tab>
                                
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;