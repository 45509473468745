import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/AcademicHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import ContactForm from "../contact/ContactForm";

const opportunities = [
  {
    title: 'Find Us',
    description: 'Share Your Location and Discover Our STEAM Solutions ',
    image: 'd'
  },
  {
    title: 'Local STEAM Experts',
    description: 'Connect with Us and Enhance Your Education',
    image: 'e'
  },
  {
    title: 'Pricing and Consultation',
    description: 'Share Your Location and Discover Our STEAM Solutions ',
    image: 'f'
  }
];



function SteamVocational() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image vocational_bg" 
                  style={{ 
                        backgroundColor: '#4ABEAD', 
                        display: 'flex',
                        backgroundPosition: 'top', 
                        alignItems: 'center',
                        position: 'relative', 
                  }}
            >
                <ElementaryHeroTabs selectedTab={'demo'}/>
                <div className="container">
                  <div className="row" style={{alignItems: 'center'}}>
                      
                      <div className="col-lg-7 about_hero_content">
                          <h2 style={{textAlign: 'start'}}>Join Our Pilot Program and Achieve Your Goals With Our Demo Product</h2>
                          <p>Contact Us to Learn More About Our Partnership Opportunities</p>
                      </div>
                      
                      <div className="col-lg-5 about_hero_image" style={{alignSelf: 'flex-end'}}>
                          <img src="/assets/images/demo/hero.webp"/>
                      </div>
                      
                      
                  </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1 steam-vocational-body">
                
              <div className="service-details-inner">
                  <div className="inner">
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 130}}>                                  
                              <div className="col-lg-6 enhance-teach-content">                                                                   
                                  <div className="service-style--3 text-left mb_sm--0">
                                      <h2 style={{marginBottom: 16, fontWeight: 600, lineHeight: '134.5%'}} className="title">Join Our Pilot Program: Try Our Demo Product for Free!</h2>                                     
                                  </div>                                                      
                              </div>
                              <div className="col-12">
                                <p className="description">Welcome to our pilot opportunity program! We're excited to offer our demo product to potential partners as a complimentary service. Our demo product is an excellent way to get a taste of our offerings and see how our solutions can benefit your organization.</p>
                              </div>
                              
                          </div>
                          <div className="row" style={{marginTop: 40}}>
                            <div className="col-lg-6" style={{paddingTop: 15, paddingBottom: 15}}>
                              <img style={{width: '100%'}} src="/assets/images/demo/1.webp"/>
                            </div>
                            <div className="col-lg-6" style={{paddingTop: 15, paddingBottom: 15}}>
                              <img style={{width: '100%'}} src="/assets/images/demo/2.webp"/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6" style={{paddingTop: 15, paddingBottom: 15}}>
                              <img style={{width: '100%'}} src="/assets/images/demo/3.webp"/>
                            </div>
                            <div className="col-lg-6" style={{paddingTop: 15, paddingBottom: 15}}>
                              <img style={{width: '100%'}} src="/assets/images/demo/4.webp"/>
                            </div>
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 130, justifyContent: 'center'}}>
                                                          
                              <div className="col-lg-8">
                                  <p style={{fontSize: 25, fontWeight: 700, textAlign: 'center', lineHeight: '50px'}}>By joining our Pilot program, you'll have access to our demo product and be able to test it out in a real-world scenario. This will give you an opportunity to see how our products work and how they can help you achieve your goals.</p>
                              </div>
                              
                          </div>
                          {/* End Single Area */}
                          {/* Start Single Area */}
                          <div className="row" style={{marginTop: 130,}}>
                              {opportunities.map( (val , i) => (
                                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 text-left" key={i} style={{paddingBottom: 30}}>
                                      <div class="card service-card" style={{border: 'none', boxShadow: '2px 3px 20px rgba(0, 0, 0, 0.14)', borderRadius: '0.65rem', height: 530 }}>
                                          <div style={{
                                              borderTopLeftRadius: '0.65rem', 
                                              borderTopRightRadius: '0.65rem', 
                                              backgroundColor: 'white', 
                                              height: 300,
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center'
                                          }}>
                                              <img style={{width: '70%'}} id="service-image" className="card-img-top" src={`/assets/images/academic/${val.image}.webp`} alt="Card image cap"/>
                                          </div>
                                          
                                          <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: '0 20px 20px 20px', justifyContent: 'flex-start', alignItems: 'center'}}>
                                             
                                                  <h5 className="card-title" style={{fontWeight: 700, marginBottom: 10}}>{val.title}</h5>
                                                  <p style={{fontSize: 16, marginBottom: 30, textAlign: 'center'}} className="card-text">{val.description}</p>
                                         
                                              
                                              <a href="/contact" className="generic-color-btn">Contact Us</a>
                                          </div>
                                      </div>
                                  </div>
                                  
                              ))}
                          </div>
                          {/* End Single Area */}

                      
                        

                          <div className="rn-contact-us ptb--30" id="contact" style={{marginTop: 120}}>
                            <div className="contact-form--1">
                                
                                <div className="row row--35" style={{alignItems: 'stretch'}}>
                                    <div className="col-12 order-2 order-lg-1">
                                        <div className="section-title text-left mb--50">
                                            <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                            <h2 style={{marginBottom: 15, fontSize: 40, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                            <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                        </div>
                                        <div className="form-wrapper">
                                            <ContactForm />
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                            <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                        </div>
                                    </div> */}
                                </div>
                                
                            </div>
                          </div>
                       
                      </div>
                      
                      {/* End Single Area */}
                      

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-540}  />

        </React.Fragment>
    )
}
export default SteamVocational;
