import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/AcademicHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import ContactForm from "../contact/ContactForm";



function Steam() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image about_bg" 
                  style={{ 
                        backgroundColor: '#4ABEAD', 
                        display: 'flex',
                        backgroundPosition: 'top', 
                        alignItems: 'center',
                        position: 'relative', 
                  }}
            >
                <ElementaryHeroTabs selectedTab={'curriculum'}/>
                <div className="container">
                  <div className="row" style={{alignItems: 'center'}}>
                      
                      <div className="col-lg-6 about_hero_content">
                          <h2 style={{textAlign: 'start'}}>Transform Learning With Blended Support</h2>
                          <p>Revolutionize your school’s learning experience with our blended teaching tools and support. Empowering decision-makers, educators, and event heads to achieve greater success and fulfillment in their roles</p>
                      </div>
                      
                      <div className="col-lg-6 about_hero_image" style={{alignSelf: 'flex-end'}}>
                          <img src="/assets/images/academic/curriculum/hero.webp"/>
                      </div>
                      
                      
                  </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1">
                
              <div className="service-details-inner">
                  <div className="inner">
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}} className="container">
                          {/* Start Single Area */}
                          <div className="row align-items-center">                                  
                              <div className="col-lg-6 enhance-teach-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
                                              <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Personalized Onboarding for Academic Success: Empowering School Decision-Makers</h2>
                                              <p className="description">Our customized onboarding process is designed to meet the unique needs of your academic institution. We collaborate with you to understand your goals and deliver a tailored experience that empowers your school decision-makers to achieve academic success. Let's revolutionize your school's learning experience together.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 enhance-teach-image">
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/academic/curriculum/1.webp" alt="About Images"/>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 100}}>
                             <div className="col-lg-6 enhance-teach-image">
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/academic/curriculum/2.webp" alt="About Images"/>
                                  </div>
                              </div>                                  
                              <div className="col-lg-6 enhance-teach-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
                                              <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">AI-Powered Monitoring & Evaluation for Schools</h2>
                                              <p className="description">Unlock the potential of your students with the ANTT Robotics capstone project-based approach. Our AI enhanced curriculum enables personalized learning and progress tracking, maximizing student understanding and growth. Empower your school with ANTT Robotics and revolutionize the way you teach.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 100}}>                                  
                              <div className="col-lg-6 enhance-teach-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
                                              <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Mastering STEM Skills for Future Success: A Comprehensive Guide for Students of All Levels</h2>
                                              <p className="description">In today's rapidly evolving world, STEM skills are essential for students to succeed in their academic and professional lives. This comprehensive guide provides an overview of the key STEM skill categories for students of all levels, from Pre-School to Higher Education. The guide covers Creativity and Innovation, Technology and Engineering, Science and Mathematics, and Collaboration and Communication. Each category includes a breakdown of the recommended skills to focus on at each education level, from basic exploration to advanced research and development. Start mastering your STEM skills today and achieve future success!</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 enhance-teach-image">
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/academic/curriculum/3.webp" alt="About Images"/>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Area */}

                          <div className="row academic-curriculum-table" style={{marginTop: 120}}>
                            <table>
                              <tr>
                                <th>PreSchool</th>
                                <th>STEM Skill Category</th>
                                <th>Elementary School</th>
                                <th>Middle Scool</th>
                                <th>High School</th>
                                <th>Vocational School</th>
                                <th>Higher Education</th>
                              </tr>
                              <tr>
                                <td>Collaboration and Communication</td>
                                <td>Exploring and experimenting with different materials and mediums</td>
                                <td>Brainstorming and ideation, simple problem-solving and design thinking</td>
                                <td>Complex problem-solving and design thinking, introduction to entrepreneurship</td>
                                <td>Critical thinking, research skills, advanced design thinking and prototyping</td>
                                <td>Application of design thinking and entrepreneurial skills in real-world projects</td>
                                <td>Advanced entrepreneurship, innovation, and product design</td>
                              </tr>
                              <tr>
                                <td>Creativity and Innovation</td>
                                <td>Basic Computer Programming - Programming learning tools for Pre-Primary to Middle Schools</td>
                                <td>Data Science & Computer Programming - IoT, Robotics & Programming learning tools for Lower Secondary to University</td>
                                <td>Advanced coding and programming, advanced robotics, and electronics</td>
                                <td>Advanced programming, web design, app development, and automation</td>
                                <td>Industrial automation, manufacturing, and robotics</td>
                                <td>Advanced industrial automation, AI, and machine learning</td>
                              </tr>
                              <tr>
                                <td>Technology and Engineering</td>
                                <td>Basic scientific inquiry and exploration</td>
                                <td>Introduction to scientific experimentation and data analysis</td>
                                <td>Advanced scientific experimentation and data analysis, introduction to mathematical modeling</td>
                                <td>Advanced mathematical modeling and data analysis, scientific research</td>
                                <td>Application of teamwork and leadership skills in real-world projects</td>
                                <td>Advanced scientific research and development</td>
                              </tr>
                              <tr>
                                <td>Science and Mathematics</td>
                                <td>Basic communication and social skills</td>
                                <td>Teamwork and collaboration, effective communication, and leadership skills</td>
                                <td>Advanced teamwork and collaboration, leadership, and project management skills</td>
                                <td>Effective communication in different contexts and media, advanced project management</td>
                                <td>Application of scientific research in real-world projects</td>
                                <td>Advanced leadership, project management, and communication skills</td>
                              </tr>
                              <tr>
                                <td colSpan="7"></td>
                              </tr>
                            </table>
                          </div>

                          
                          {/* End Single Area */}

            
                        

                          <div className="rn-contact-us ptb--30" id="contact" style={{marginTop: 100}}>
                            <div className="contact-form--1">
                                
                                <div className="row row--35" style={{alignItems: 'stretch'}}>
                                    <div className="col-12 order-2 order-lg-1">
                                        <div className="section-title text-left mb--50">
                                            <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                            <h2 style={{marginBottom: 15, fontSize: 40, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                            <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                        </div>
                                        <div className="form-wrapper">
                                            <ContactForm />
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                            <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                        </div>
                                    </div> */}
                                </div>
                                
                            </div>
                          </div>
                       
                      </div>
                      
                      {/* End Single Area */}
                      

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-600}  />

        </React.Fragment>
    )
}
export default Steam;
