import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactTwo extends Component {
    render(){
        return(
            <div className="contact-form--1">
                <div style={{transform: 'scale(0.8)'}} className="container">
                    <div className="row row--35" style={{alignItems: 'stretch'}}>
                        <div className="col-12 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <p style={{color: '#2c7fc2', marginBottom: 2}}>CONTACT WITH US!</p>
                                <h2 style={{marginBottom: 15}} className="title">Have Any Questions?</h2>
                                <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                            </div>
                            <div className="form-wrapper">
                                <ContactForm />
                            </div>
                        </div>
                        {/* <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;